import { pnrBeforeNIService, pnrAfterNIService } from "../services/pnrService";
import {
    cardPaymentService,
    paymentStatusService,
} from "../services/paymentService";

import { couponCodeService } from "../services/couponCodeService.js";
import { accountService } from "../services/accountService";
import {
    addtoCartService,
    editCartService,
    removeItemFromCart,
} from "../services/cartService";
import { pdfService } from "../services/pdfService";
import { getTickets } from "../services/getTickets";
import {
    actionAddProducts,
    actionAddTicketsData,
    actionAddToCartItem,
    actionBookingJourneyStepsHandler,
    actionEmptyUnusedValues,
    actionIsLoading,
    actionIsMatchPID,
    actionResetBookingJourneyReducer,
    actionSaveAddOnsData,
    actionSaveAddToCartResponse,
    actionSaveCartAmount,
    actionSaveEditToCartResponse,
    actionSaveItemsToBeAdd,
    actionSaveItemsToBeUpdate,
    actionSaveLockerData,
    actionSavePerformanceId,
    actionSavePIdForEvent,
    actionSaveRemoveFromCartResponse,
    actionTemporarySaveAddOns,
    actionUpdateProducts,
} from "./bookingJourneyActions";

import toastr from "toastr";
import { countryCodeAlphaCodes } from "../services/countryCodes";
import { emptyShopCart } from "../services/emptyShopCart";
import { createMemberAccount } from "../services/createMemberAccount";
import { setItemDetails } from "../services/setItemDetails";
import {
    TicketCategoryList,
    OnlyTickets,
    OnlyAddons,
    OnlyLockers,
} from "../constants";

export const UPDATE_BOOKINGDETAILS_BYKEY = "UPDATEBOOKINGDETAILSBYKEY";
export const RESET_BOOKINGDETAILS_BYKEY = "RESET_BOOKINGDETAILS_BYKEY";

export const SAVE_TOTAL_TICKET_QUANTITY = "SAVE_TOTAL_TICKET_QUANTITY";

export function actionSaveTotalTicketQuantity(payload) {
    console.log("@@payload",payload)
  return { 
        type: SAVE_TOTAL_TICKET_QUANTITY, 
        payload 
    };
};

export function updateBookingDetailsbyKey(payload) {
    return {
        type: UPDATE_BOOKINGDETAILS_BYKEY,
        payload,
    };
}

export function resetBookingDetailsbyKey(payload) {
    return {
        type: RESET_BOOKINGDETAILS_BYKEY,
        payload,
    };
}

export function fetchTickets() {
    return async (dispatch, getState) => {
        //console.log("OnlyTicketsAndAddons", OnlyTicketsAndAddons);
        //console.log("OnlyTickets", OnlyTickets);

        const { bookingJourney } = getState();

        let requestPayload = {
            bookingdate: bookingJourney.bookingDate,
        };
        dispatch(actionIsLoading(true));

        //console.log("bookingJourney", bookingJourney);

        return new Promise(async (resolve, reject) => {
            return await getTickets(requestPayload)
                .then((response) => {
                    dispatch(actionIsLoading(false));
                   // dispatch(actionBookingJourneyStepsHandler(1));
                    dispatch(
                        updateBookingDetailsbyKey({
                            availableTickets: response.data.data.productList,
                        })
                    );

                    if (response.data.data.productList.length > 0) {

                        let ticketDatas = [];
                        let AddonsData = [];
                        let lockers = [];

                        const catType = bookingJourney.selectPassCategory.passCategory
                            .toLowerCase()
                            .trim();

                        //console.log('catType', catType);

                        let ticketCategory = bookingJourney.pageData.fields["Booking Category List"][0].id;                       
                        let ticketCategoryList = bookingJourney.pageData.fields["Booking Category List"][0];   

                        //console.log("Booking Category List", ticketCategoryList);

                        response.data.data.productList.map((tickets, index) => {
                            //on the basis of category Type Selection
                            //console.log("tickets", tickets);

                            ticketCategoryList.fields["CategoryList"].map((stickets, sindex) => {
                                //console.log("sitecoretickets", stickets);
                                //console.log("Adult ProductId", stickets.fields["Adult ProductId"]);
                                //console.log("Child ProductId", stickets.fields["Child ProductId"]);
                                //console.log("VGS ProductId", tickets.ProductId);
                                if (tickets.ProductId == stickets.fields["Adult ProductId"].value ||
                                    tickets.ProductId == stickets.fields["Child ProductId"].value) {
                                    ticketDatas.push({ ...tickets, showQty: 0 });
                                }
                            });

                            ticketCategoryList.fields["Add On List"].map((saddOns, aindex) => {
                                //console.log("Add Ons", saddOns);
                                //console.log("AddOn ProductId", saddOns.fields["ProductId"]);
                                //console.log("Child ProductId", stickets.fields["Child ProductId"]);
                                //console.log("VGS ProductId", tickets.ProductId);
                                if (tickets.ProductId == saddOns.fields["ProductId"].value) {
                                    AddonsData.push({ ...tickets, showQty: 0 });
                                }
                            });
                            if (OnlyLockers.includes(tickets.ProductId)) {
                                lockers.push({ ...tickets, showQty: 0 });
                            }

                        });
                        // dispatch(actionSaveLockerData(lockers));
                        dispatch(actionAddTicketsData(ticketDatas));
                        let filteredAddons =
                            AddonsData.length > 0 &&
                            AddonsData.filter((filVal) => {
                                if (
                                    filVal.ProductName.toLowerCase().includes("female") !== true &&
                                    filVal.ProductName.toLowerCase().includes("small") !== true &&
                                    filVal.ProductName.toLowerCase().includes("medium") !== true
                                ) {
                                    return filVal;
                                }
                            });

                        if (filteredAddons.length > 0) {
                            dispatch(actionSaveAddOnsData(filteredAddons));
                            dispatch(actionTemporarySaveAddOns(filteredAddons));
                        }
                    }

                    dispatch(
                        actionSavePerformanceId({
                            ticketPId: response.data.data.PerformanceId.tickets,
                            lockerPId: response.data.data.PerformanceId.lockers,
                        })
                    );
                    //if (bookingJourney.bookingJourneySteps == 0) {
                    //    dispatch(actionBookingJourneyStepsHandler(1));
                    //}
                    resolve({ status: 200, message: response.data.data.productList });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(actionIsLoading(false));
                    reject({ status: 500, message: err });
                });
        });
    };
}

export function addToCart() {
    return async (dispatch, getState) => {
        const { bookingJourney, bookingDetailsV2 } = getState();

        let shopCardIdPresent;
        if (
            bookingJourney.addToCartResponse &&
            bookingJourney.addToCartResponse.ShopCartId
        ) {
            shopCardIdPresent = bookingJourney.addToCartResponse.ShopCartId;
        } else {
            shopCardIdPresent = "";
        }
        if (shopCardIdPresent == "") {
            // Store current time
            const currentTimeString = new Date();
            // sessionStorage.setItem("current_time", currentTimeString);
            localStorage.setItem("current_time", currentTimeString);
        }
        let performanceId;
        let allAddtoCartProducts = [];
        let allAddtoCartProductsPrice = [];

        if (bookingJourney.AddProducts.length > 0) {
            bookingJourney.AddProducts.map((data) => {
                let getSingleValue = bookingDetailsV2.availableTickets.find(
                    (value, index) => value.ProductId === data.ProductId
                );
                if (Object.keys(getSingleValue).length > 0) {
                    if (getSingleValue.ProductName.includes("Locker")) {
                        performanceId = bookingJourney.performanceId.lockerPId;
                    } else {
                        performanceId = bookingJourney.performanceId.ticketPId;
                    }
                }

                allAddtoCartProducts.push({
                    Product: {
                        ProductId: data.ProductId,
                    },
                    QuantityDelta: data.showQty,
                    PerformanceIDs: performanceId,
                });
                dispatch(actionSavePIdForEvent(data.ProductId));

                allAddtoCartProductsPrice.push(data.Price);
            });
        }

        let requestPayload = {
            productDetail: allAddtoCartProducts,
            ShopCartId: shopCardIdPresent,
        };
        let allTickets = bookingJourney.backendData.ticketsData.concat(
            bookingJourney.backendData.AddOnData
        );
        console.log("allAddtoCartProducts", allAddtoCartProducts);
        if (allAddtoCartProducts.length > 0) {

            allAddtoCartProducts.forEach((item, i) => {
                if (OnlyTickets.includes(item.Product.ProductId)) {
                    window.dataLayer.push({
                        event: "add_to_cart_ticket",
                        page_type: bookingJourney.pageData.name,
                        page_language: bookingJourney.pageData.itemLanguage,
                        arrival_date: bookingJourney.bookingDate,
                        ticket_name: getProduct(allTickets, item.Product.ProductId)
                            .ProductName,
                        ticket_qty: item.QuantityDelta,
                        ticket_amount: `${allAddtoCartProductsPrice[i]}`,
                    });
                }
            });

            let addToCartGA4 = [];
            allAddtoCartProducts.forEach((cartItem) => {
                if (
                    OnlyAddons.includes(cartItem.Product.ProductId) ||
                    OnlyLockers.includes(cartItem.Product.ProductId)
                ) {
                    const newItem = {
                        item_id: cartItem.Product.ProductId,
                        item_name: getProduct(allTickets, cartItem.Product.ProductId)
                            .ProductName,
                        affiliation: "Wild Wadi",
                        index: 0,
                        item_category: "Extras",
                        price: getProduct(allTickets, cartItem.Product.ProductId).Price,
                        quantity:
                            cartItem.QuantityDelta == null ? 1 : cartItem.QuantityDelta,
                    };

                    addToCartGA4.push(newItem);
                } else
                {
                    //console.log("passCategory", bookingJourney.selectPassCategory.passCategory);

                    addToCartGA4.push({
                        item_id: cartItem.Product.ProductId,
                        item_name: getProduct(allTickets, cartItem.Product.ProductId)
                            .ProductName,
                        affiliation: "Wild Wadi",
                        index: 0,
                        item_category: bookingJourney.selectPassCategory.passCategory,
                        item_category2: "",
                        price: getProduct(allTickets, cartItem.Product.ProductId).Price,
                        quantity:
                            cartItem.QuantityDelta == null ? 1 : cartItem.QuantityDelta,
                    });
                }
            });

            // GA4 Event
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: "add_to_cart",
                transaction_date: bookingJourney.bookingDate,
                booking_date: bookingJourney.bookingDate,
                ecommerce: {
                    currency: "AED",
                    value: allAddtoCartProductsPrice.reduce((acc, curr) => acc + curr, 0),
                    items: addToCartGA4,
                },
            });

            console.log("add_to_cart", addToCartGA4);
        }
        
        return new Promise(async (resolve, reject) => {
            return await addtoCartService(requestPayload)
                .then((addtoCartResponse) => {
                    dispatch(actionSaveAddToCartResponse(addtoCartResponse));
                    let addTocartRes =
                        addtoCartResponse.Items.length > 0 &&
                        addtoCartResponse.Items.filter((value, index) => {
                            return !value.ProductName.includes("DIscount");
                        });
                    let ADD_TO_CART_RES = {
                        Items: addTocartRes,
                        ShopCartId: addtoCartResponse.ShopCartId,
                        SubTotal: addtoCartResponse.SubTotal,
                        TotalAmount: addtoCartResponse.TotalAmount,
                        TotalDiscount: addtoCartResponse.TotalDiscount,
                        TotalQuantity: addtoCartResponse.TotalQuantity,
                        TotalTax: addtoCartResponse.TotalTax,
                    };
                    let cartItem = [];
                    let commonAddToCartDetails;
                    if (Object.keys(addtoCartResponse).length > 0) {
                        addtoCartResponse.Items.map((singleItem, i) => {
                            cartItem.push({
                                productId: singleItem.ProductId,
                                ProductName: singleItem.ProductName,
                                SubTotal: singleItem.SubTotal,
                                TotalAmount: singleItem.TotalAmount,
                                TotalQuantity: singleItem.TotalQuantity,
                                TotalTax: singleItem.TotalTax,
                                TotalDiscount: singleItem.TotalDiscount,
                                ShopCartItemId: singleItem.ShopCartItemId,
                            });
                            addtoCartResponse.Items.filter((res) => {
                                if (res.ProductName.toLowerCase().includes("locker")) {
                                    console.log("Lockersss", res);
                                    let updatedLockers = bookingJourney.lockers.forEach(
                                        (item) => {
                                            if (item.ProductId == res.ProductId) {
                                                item.showQty = res.TotalQuantity;
                                            }
                                        }
                                    );
                                    console.log("EEEEE", updatedLockers);
                                    // dispatch(actionSaveLockerData(updatedLockers));
                                }
                            });
                        });
                        commonAddToCartDetails = {
                            ShopCartId: addtoCartResponse.ShopCartId,
                            SubTotal: addtoCartResponse.SubTotal,
                            TotalAmount: addtoCartResponse.TotalAmount,
                            TotalQuantity: addtoCartResponse.TotalQuantity,
                            TotalTax: addtoCartResponse.TotalTax,
                            TotalDiscount: addtoCartResponse.TotalDiscount,
                        };
                        //if (cartItem.length > 0) {
                        //    let AddOnsData = cartItem.filter((filValue) => {
                        //        return OnlyAddons.includes(filValue.productId);
                        //    });

                        //    AddOnsData.length > 0 &&
                        //        AddOnsData.filter(
                        //            (filVal) => filVal.productId == bookingJourney.PidForEvent
                        //        ).forEach((addon) => {
                        //            window.dataLayer.push({
                        //                event: "add_to_cart_addon",
                        //                page_type: bookingJourney.pageData.name,
                        //                page_language: bookingJourney.pageData.itemLanguage,
                        //                addon_name: addon.ProductName,
                        //                addon_qty: addon.TotalQuantity,
                        //                addon_rate: `${addon.TotalAmount}`,
                        //                total_addon_rate: `${addon.TotalAmount}`,
                        //            });
                        //        });
                        //}
                        dispatch(actionSaveItemsToBeAdd([]));
                        dispatch(actionAddProducts([]));
                        dispatch(actionAddToCartItem(cartItem));
                        dispatch(actionSaveCartAmount(commonAddToCartDetails));
                        dispatch(actionIsMatchPID(""));
                        toastr.success("Item Added To Cart");
                    }
                    dispatch(
                        updateBookingDetailsbyKey({
                            cartBase: { ...requestPayload.cartBase, ...ADD_TO_CART_RES },
                        })
                    );
                    resolve({ status: 200, message: ADD_TO_CART_RES });
                })
                .catch((err) => {
                    reject({ status: 500, message: err });
                });
        });
    };
}

export function removeItem() {
    return async (dispatch, getState) => {
        const { bookingJourney } = getState();
        let requestPayload = {
            ShopCartId: bookingJourney.removeFromCartRequest.ShopCartId,
            ShopCartItemId: bookingJourney.removeFromCartRequest.ShopCartItemId,
        };

        console.log("remove", requestPayload);

        return new Promise(async (resolve, reject) => {
            return await removeItemFromCart(requestPayload)
                .then((removeItemFromCartResponse) => {
                    dispatch(
                        actionSaveRemoveFromCartResponse(removeItemFromCartResponse)
                    );
                    let commonRemoveFromCartDetails;
                    let cartItem = [];
                    if (Object.keys(removeItemFromCartResponse).length > 0) {
                        removeItemFromCartResponse.Items.map((singleItem, i) => {
                            cartItem.push({
                                productId: singleItem.ProductId,
                                ProductName: singleItem.ProductName,
                                SubTotal: singleItem.SubTotal,
                                TotalAmount: singleItem.TotalAmount,
                                TotalQuantity: singleItem.TotalQuantity,
                                TotalTax: singleItem.TotalTax,
                                ShopCartItemId: singleItem.ShopCartItemId,
                                TotalDiscount: singleItem.TotalDiscount,
                            });
                        });
                        commonRemoveFromCartDetails = {
                            ShopCartId: removeItemFromCartResponse.ShopCartId,
                            SubTotal: removeItemFromCartResponse.SubTotal,
                            TotalAmount: removeItemFromCartResponse.TotalAmount,
                            TotalQuantity: removeItemFromCartResponse.TotalQuantity,
                            TotalTax: removeItemFromCartResponse.TotalTax,
                            TotalDiscount: removeItemFromCartResponse.TotalDiscount,
                        };
                        dispatch(actionSaveCartAmount(commonRemoveFromCartDetails));
                        if (cartItem.length > 0) {
                            dispatch(actionAddToCartItem(cartItem));
                        } else {
                            dispatch(actionSaveRemoveFromCartResponse({}));
                            dispatch(actionAddToCartItem([]));
                        }

                        dispatch(actionIsMatchPID(""));

                        toastr.success("Item Removed From Cart");
                    }
                    resolve({ status: 200, message: removeItemFromCartResponse });
                })
                .catch((err) => {
                    console.log(err);
                    reject({ status: 500, message: err });
                });
        });
    };
}

export function editCart() {
    return async (dispatch, getState) => {
        const { bookingJourney } = getState();
        let allTickets = bookingJourney.backendData.ticketsData.concat(
            bookingJourney.backendData.AddOnData
        );
        let editItemBody = [];
        if (bookingJourney.UpdateProducts.length > 0) {
            bookingJourney.UpdateProducts.map((editData) => {
                editItemBody.push({
                    ShopCartId: bookingJourney.addToCartResponse.ShopCartId,
                    ShopCartItemId: bookingJourney.addToCartResponse.Items.filter(
                        (filVal) => filVal.ProductId == editData.ProductId
                    )[0].ShopCartItemId,
                    QuantityDelta: editData.showQty,
                });
                dispatch(actionSavePIdForEvent(editData.ProductId));

                addToCartGA4EventCall(
                    editData.ProductId,
                    editData.showQty,
                    bookingJourney,
                    allTickets
                );
            });
        }
        let requestPayload = editItemBody;

        return new Promise(async (resolve, reject) => {
            return await editCartService(requestPayload)
                .then((editCartResponse) => {
                    let commonEditToCartDetails;
                    let editCartArr = [];

                    let cartItem = [];

                    if (Object.keys(editCartResponse).length > 0) {
                        editCartResponse.Items.map((singleItem, i) => {
                            editCartArr.push({
                                productId: singleItem.ProductId,
                                ProductName: singleItem.ProductName,
                                SubTotal: singleItem.SubTotal,
                                TotalAmount: singleItem.TotalAmount,
                                TotalQuantity: singleItem.TotalQuantity,
                                TotalTax: singleItem.TotalTax,
                                ShopCartItemId: singleItem.ShopCartItemId,
                                TotalDiscount: singleItem.TotalDiscount,
                            });
                        });
                        commonEditToCartDetails = {
                            ShopCartId: editCartResponse.ShopCartId,
                            SubTotal: editCartResponse.SubTotal,
                            TotalAmount: editCartResponse.TotalAmount,
                            TotalQuantity: editCartResponse.TotalQuantity,
                            TotalTax: editCartResponse.TotalTax,
                            TotalDiscount: editCartResponse.TotalDiscount,
                        };

                        //if (editCartArr.length > 0) {
                        //    let AddOnsData = editCartArr.filter((filValue) => {
                        //        return OnlyAddons.includes(filValue.productId);
                        //    });
                        //    if (
                        //        bookingJourney.actionPerformed !== "" &&
                        //        bookingJourney.actionPerformed !== "decreaseAddons"
                        //    ) {
                        //        let singleAddon;
                        //        singleAddon =
                        //            bookingJourney.backendData.AddOnData &&
                        //            bookingJourney.backendData.AddOnData.length > 0 &&
                        //            bookingJourney.backendData.AddOnData.find(
                        //                (item) => item.ProductId == bookingJourney.PidForEvent
                        //            );
                        //        AddOnsData.length > 0 &&
                        //            AddOnsData.filter(
                        //                (filVal) => filVal.productId == bookingJourney.PidForEvent
                        //            ).forEach((addon) => {
                        //                window.dataLayer.push({
                        //                    event: "add_to_cart_addon",
                        //                    page_type: bookingJourney.pageData.name,
                        //                    page_language: bookingJourney.pageData.itemLanguage,
                        //                    addon_name: singleAddon.ProductName,
                        //                    addon_qty: 1,
                        //                    addon_rate: `${singleAddon.Price}`,
                        //                    total_addon_rate: `${singleAddon.Price}`,
                        //                });
                        //            });
                        //    }
                        //}
                        dispatch(actionSaveItemsToBeUpdate([]));
                        dispatch(actionUpdateProducts([]));

                        if (cartItem !== undefined) {
                            dispatch(actionAddToCartItem(editCartArr));
                        }

                        dispatch(actionSaveCartAmount(commonEditToCartDetails));
                        toastr.success("Item Updated To Cart");
                        dispatch(actionIsMatchPID(""));
                    }

                    dispatch(actionSaveEditToCartResponse(editCartResponse));
                    dispatch(
                        updateBookingDetailsbyKey({
                            cartBase: {
                                ...requestPayload.cartBase,
                                ...editCartResponse,
                            },
                        })
                    );
                    resolve({ status: 200, message: editCartResponse });
                })
                .catch((err) => {
                    console.log(err);
                    reject({ status: 500, message: err });
                });
        });
    };
}

export const applyDiscount = () => {
    return async (dispatch, getState) => {
        const { bookingDetailsV2, bookingJourney } = getState();
        //console.log('bookingJourney.AddToCartDetails.ShopCartId', bookingJourney.AddToCartDetails.ShopCartId);
        //console.log('bookingDetailsV2.cartBase.ShopCartId', bookingDetailsV2.cartBase.ShopCartId);
        let requestPayload = {
            ShopCartId: bookingDetailsV2.cartBase.ShopCartId,
            couponCode: bookingDetailsV2.couponCode.value,
        };
        return new Promise(async (resolve, reject) => {
            return await couponCodeService(requestPayload)
                .then((couponResponse) => {
                    dispatch(
                        updateBookingDetailsbyKey({
                            cartBase: { ...bookingDetailsV2.cartBase, ...couponResponse },
                        })
                    );
                    //reset couponcode in redux after trying once,user needs to type the code again to retry.
                    dispatch(
                        updateBookingDetailsbyKey({
                            couponCode: "",
                        })
                    );
                    resolve({
                        status: 200,
                        message: "Coupon Applied",
                        data: couponResponse,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        updateBookingDetailsbyKey({
                            couponCode: "",
                        })
                    );
                    reject({ status: 500, message: err });
                });
        });
    };
};

export function applyEmptyShopCart() {
    return async (dispatch, getState) => {
        const { bookingDetailsV2 } = getState();
        let requestPayload = {
            ShopCartId: bookingDetailsV2.cartBase.ShopCartId,
        };
        dispatch(actionIsLoading(true));
        return new Promise(async (resolve, reject) => {
            return await emptyShopCart(requestPayload)
                .then((response) => {
                    console.log("EMPTY SHOPCART", response.data);
                    if (
                        response.data.data.Header.StatusCode == 200 &&
                        response.data.data.Answer
                    ) {
                        dispatch(actionBookingJourneyStepsHandler(0));
                    }
                    dispatch(actionIsLoading(false));
                    resolve({ status: 200, message: response });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(actionIsLoading(false));
                    dispatch(actionEmptyUnusedValues());
                    reject({ status: 500, message: err });
                });
        });
    };
}

//since all calls happen after confirm button is clicked we are grouping them as beforepaymentservice and afterpaymentservice
//execute  services one after the other

//accountService->pnrNumber->nipayments->paymentstatus->pnrdeletion(if payment failed)->pnrAfterNiservice and redirect confirmation page (if payment success)

export function confirmationBeforePayment() {
    return async (dispatch, getState) => {
        const { bookingDetailsV2, bookingJourney } = getState();
        let userData = {
            accountDetail: [
                {
                    Value: bookingDetailsV2.mainGuestDetails.firstName.value,
                    MetaFieldCode: "FT1",
                },
                {
                    Value: bookingDetailsV2.mainGuestDetails.lastName.value,
                    MetaFieldCode: "FT3",
                },
                {
                    Value: bookingDetailsV2.mainGuestDetails.title.value,
                    MetaFieldCode: "FT5",
                },
                {
                    Value: bookingDetailsV2.mainGuestDetails.email.value,
                    MetaFieldCode: "FT21",
                },
                {
                    Value: bookingDetailsV2.mainGuestDetails.phone.value,
                    MetaFieldCode: "FT15",
                },
                {
                    Value:
                        countryCodeAlphaCodes[
                        bookingDetailsV2.mainGuestDetails.country.code
                        ],
                    MetaFieldCode: "DIALCODE",
                },
                {
                    Value: bookingDetailsV2.mainGuestDetails.countryCode.code,
                    MetaFieldCode: "FT11",
                },
            ],
        };
        let finalCartBase = {
            baseProducts: [],
            shopCartId: bookingDetailsV2.cartBase.ShopCartId,
            performanceId: "",
            items: bookingDetailsV2.cartBase.Items,
            subTotal: bookingDetailsV2.cartBase.SubTotal,
            totalTax: bookingDetailsV2.cartBase.TotalTax,
            totalAmount: bookingDetailsV2.cartBase.TotalAmount,
            totalDiscount: bookingDetailsV2.cartBase.TotalDiscount,
        };

        //console.log("categoryID", bookingDetailsV2.categoryid);

        console.log("confirmationBeforePayment - bookingJourney - selectedTourType", bookingJourney.selectedTourType)

        let requestPayloadAccount = {
            mainGuestDetails: userData.accountDetail,
            outletReference: bookingDetailsV2.outletReference,
            ShopCartId: bookingDetailsV2.cartBase.ShopCartId,
            CategoryIDs: bookingDetailsV2.categoryid,
        };

        let requestPayload = {
            ShopCartId: bookingDetailsV2.cartBase.ShopCartId,
        };

        let accountServiceResponse = await accountService(requestPayloadAccount);

        //check for apply coupon
        if (accountServiceResponse) {
            if (Object.keys(bookingJourney.discountItems).length > 0) {
                let paymentPayload = {
                    cartBase: finalCartBase,
                    mainGuestDetails: bookingDetailsV2.mainGuestDetails,
                    outletReference: bookingDetailsV2.outletReference,
                    paymentMethod: bookingDetailsV2.paymentMethod,
                    sessionId: bookingDetailsV2.sessionId,
                    ShopCartId: bookingDetailsV2.cartBase.ShopCartId,
                };
                if (
                    bookingDetailsV2.paymentMethod == "Apple_Pay" ||
                    bookingDetailsV2.paymentMethod == "Samsung_Pay"
                ) {
                    // Apple pay
                    await cardPaymentService(paymentPayload)
                        .then(async (paymentServiceResponse) => {
                            //reset pnrInfo in redux before payment
                            dispatch(
                                updateBookingDetailsbyKey({
                                    pnrInfo: {
                                        saleCode: "",
                                        paymentStatus: false,
                                        saleId: "",
                                        transactionId: "",
                                    },
                                })
                            );

                            dispatch(
                                updateBookingDetailsbyKey({
                                    paymentInfo: paymentServiceResponse,
                                })
                            );
                            setTimeout(() => { }, 1000);

                            if (bookingDetailsV2.paymentMethod == "Apple_Pay") {
                                console.log(
                                    "APPLE_PAY_URL",
                                    paymentServiceResponse.applePaypaymentURL
                                );
                                console.log(
                                    "wallet_token",
                                    paymentServiceResponse.wallet_token
                                );
                                const applePayObject = {
                                    APPLE_PAY_URL: paymentServiceResponse.applePaypaymentURL,
                                    wallet_token: paymentServiceResponse.wallet_token,
                                    payment_amount: paymentServiceResponse.payment_amount,
                                };
                                sessionStorage.setItem(
                                    "ApplePay_payment",
                                    JSON.stringify(applePayObject)
                                );
                                return true;
                            }
                        })
                        .catch((data) => {
                            dispatch(
                                updateBookingDetailsbyKey({
                                    pnrInfo: {
                                        ...bookingDetailsV2.pnrInfo,
                                        paymentStatus: false,
                                    },
                                    paymentInfo: data,
                                })
                            );
                            window.open(
                                window.location.origin +
                                `/${window.location.pathname.substring(
                                    1,
                                    3
                                )}/booking/booking-confirmation`,
                                "_self"
                            );
                            console.log("error in payment service NI");
                        });
                } else {
                    dispatch(
                        updateBookingDetailsbyKey({
                            pnrInfo: {
                                saleCode: "",
                                paymentStatus: false,
                                saleId: "",
                                transactionId: "",
                            },
                        })
                    );

                    console.log("cartBase - totalAmount", bookingDetailsV2.cartBase.TotalAmount);
                    console.log("finalCartBase - totalAmount", finalCartBase.totalAmount);

                    if (finalCartBase.totalAmount > 0) {
                        await cardPaymentService(paymentPayload)
                            .then((paymentServiceResponse) => {
                                dispatch(
                                    updateBookingDetailsbyKey({
                                        paymentInfo: paymentServiceResponse,
                                    })
                                );
                                window.open(
                                    window.location.origin +
                                    `/${window.location.pathname.substring(
                                        1,
                                        3
                                    )}/booking/booking-confirmation`,
                                    "_self"
                                );
                                return true;
                            })
                            .catch(async (data) => {
                                dispatch(
                                    updateBookingDetailsbyKey({
                                        pnrInfo: {
                                            ...bookingDetailsV2.pnrInfo,
                                            paymentStatus: false,
                                        },
                                        paymentInfo: data,
                                    })
                                );
                                window.open(
                                    window.location.origin +
                                    `/${window.location.pathname.substring(
                                        1,
                                        3
                                    )}/booking/booking-confirmation`,
                                    "_self"
                                );
                                console.log("error in payment service NI");
                            });
                    }
                    else {

                        dispatch(
                            updateBookingDetailsbyKey({
                                pnrInfo: {
                                    ...bookingDetailsV2.pnrInfo,
                                    paymentStatus: false,
                                }
                            })
                        );
                        window.open(
                            window.location.origin +
                            `/${window.location.pathname.substring(
                                1,
                                3
                            )}/booking/booking-confirmation`,
                            "_self"
                        );
                        return true;
                    }                    
                }
            } else {
                // If Annual Pass ==> Member Account >> Set Item Details >> PNR before NI
                // If Day Pass ==> PNR before NI
                if (bookingJourney.selectedTourType.ticketCategory && (bookingJourney.selectedTourType.ticketCategory == TicketCategoryList.annualPass ||
                    bookingJourney.selectedTourType.ticketCategory == TicketCategoryList.uaeannualPass))
                {
                    return new Promise(async (resolve, reject) => {
                        let memberAccountPayload = bookingJourney.memberDetails;

                        const result = [];

                        // Loop through each membership type
                        for (const membershipType in memberAccountPayload) {
                            if (memberAccountPayload.hasOwnProperty(membershipType)) {
                                memberAccountPayload[membershipType].forEach((member) => {
                                    result.push(member);
                                });
                            }
                        }
                        return await createMemberAccount(result)
                            .then(async (memberResponse) => {
                                let memberDetails = memberResponse.data.data;
                                let detailPositions = {};
                                memberDetails.forEach((item) => {
                                    if (!detailPositions[item.shopCartItemId]) {
                                        detailPositions[item.shopCartItemId] = 1;
                                    } else {
                                        detailPositions[item.shopCartItemId]++;
                                    }
                                    item.DetailPosition = detailPositions[item.shopCartItemId];
                                    delete item.status; // Remove the "status" property
                                    delete item.memberName; // Remove the "memberName" property
                                    delete item.error; // Remove the "error" property
                                });
                                let setItemDetailsPayload = memberDetails.map((item) => ({
                                    ShopCartItemId: item.shopCartItemId, // Change key here
                                    DetailPosition: item.DetailPosition,
                                    AccountId: item.AccountId,
                                }));

                                let payload = {
                                    data: setItemDetailsPayload,
                                    shopCartId: bookingJourney.addToCartResponse.ShopCartId,
                                };
                                return await setItemDetails(payload)
                                    .then(async (detailsRes) => {
                                        let requestPayloadAnnualPass = {
                                            ShopCartId: payload.shopCartId,
                                        };
                                        //pnr before after set item
                                        return await pnrBeforeNIService(requestPayloadAnnualPass)
                                            .then(async (pnrBeforeNIServiceResponse) => {
                                                console.log(
                                                    "pnrBeforeNIServiceResponse >>>>>>>>",
                                                    pnrBeforeNIServiceResponse
                                                );
                                                dispatch(
                                                    updateBookingDetailsbyKey({
                                                        pnrInfo: {
                                                            saleCode: pnrBeforeNIServiceResponse.saleCode,
                                                            paymentStatus:
                                                                pnrBeforeNIServiceResponse.paymentStatus,
                                                            saleId: pnrBeforeNIServiceResponse.saleId,
                                                            transactionId:
                                                                pnrBeforeNIServiceResponse.transactionId,
                                                        },
                                                        cartBase: {
                                                            ...bookingDetailsV2.cartBase,
                                                            shopCartId: requestPayloadAnnualPass.ShopCartId,
                                                        },
                                                    })
                                                );

                                                if (pnrBeforeNIServiceResponse) {
                                                    let paymentPayload = {
                                                        cartBase: {
                                                            ...finalCartBase,
                                                            shopCartId: requestPayloadAnnualPass.ShopCartId,
                                                        },
                                                        mainGuestDetails: bookingDetailsV2.mainGuestDetails,
                                                        outletReference: bookingDetailsV2.outletReference,
                                                        paymentMethod: bookingDetailsV2.paymentMethod,
                                                        sessionId: bookingDetailsV2.sessionId,
                                                        saleCode: pnrBeforeNIServiceResponse.saleCode,
                                                        ShopCartId: requestPayloadAnnualPass.ShopCartId,
                                                    };
                                                    // if (
                                                    //   bookingDetailsV2.paymentMethod == "Apple_Pay" ||
                                                    //   bookingDetailsV2.paymentMethod == "Samsung_Pay"
                                                    // ) {
                                                    //   // Apple pay
                                                    //   await cardPaymentService(
                                                    //     paymentPayload,
                                                    //     pnrBeforeNIServiceResponse
                                                    //   )
                                                    //     .then(async (paymentServiceResponse) => {

                                                    //       //reset pnrInfo in redux before payment
                                                    //       dispatch(
                                                    //         updateBookingDetailsbyKey({
                                                    //           pnrInfo: {
                                                    //             saleCode: "",
                                                    //             paymentStatus: false,
                                                    //             saleId: "",
                                                    //             transactionId: "",
                                                    //           },
                                                    //         })
                                                    //       );

                                                    //       dispatch(
                                                    //         updateBookingDetailsbyKey({
                                                    //           paymentInfo: paymentServiceResponse,
                                                    //         })
                                                    //       );
                                                    //       if (
                                                    //         bookingDetailsV2.paymentMethod == "Apple_Pay"
                                                    //       ) {
                                                    //         console.log(
                                                    //           "APPLE_PAY_URL",
                                                    //           paymentServiceResponse.applePaypaymentURL
                                                    //         );
                                                    //         console.log(
                                                    //           "wallet_token",
                                                    //           paymentServiceResponse.wallet_token
                                                    //         );
                                                    //         const applePayObject = {
                                                    //           APPLE_PAY_URL:
                                                    //             paymentServiceResponse.applePaypaymentURL,
                                                    //           wallet_token:
                                                    //             paymentServiceResponse.wallet_token,
                                                    //           payment_amount:
                                                    //             paymentServiceResponse.payment_amount,
                                                    //         };
                                                    //         sessionStorage.setItem(
                                                    //           "ApplePay_payment",
                                                    //           JSON.stringify(applePayObject)
                                                    //         );

                                                    //         resolve();
                                                    //         // return true;
                                                    //       }
                                                    //     })
                                                    //     .catch((data) => {
                                                    //       dispatch(
                                                    //         updateBookingDetailsbyKey({
                                                    //           pnrInfo: {
                                                    //             ...bookingDetailsV2.pnrInfo,
                                                    //             paymentStatus: false,
                                                    //           },
                                                    //           paymentInfo: data,
                                                    //         })
                                                    //       );
                                                    //       // window.open(
                                                    //       //   window.location.origin +
                                                    //       //     `/${window.location.pathname.substring(
                                                    //       //       1,
                                                    //       //       3
                                                    //       //     )}/booking/booking-confirmation`,
                                                    //       //   "_self"
                                                    //       // );
                                                    //       console.log("error in payment service NI");
                                                    //     });
                                                    // } else {
                                                    await cardPaymentService(paymentPayload)
                                                        .then((paymentServiceResponse) => {
                                                            dispatch(
                                                                updateBookingDetailsbyKey({
                                                                    paymentInfo: paymentServiceResponse,
                                                                })
                                                            );
                                                            window.open(
                                                                window.location.origin +
                                                                `/${window.location.pathname.substring(
                                                                    1,
                                                                    3
                                                                )}/booking/booking-confirmation`,
                                                                "_self"
                                                            );
                                                            return true;
                                                        })
                                                        .catch((data) => {
                                                            dispatch(
                                                                updateBookingDetailsbyKey({
                                                                    pnrInfo: {
                                                                        saleCode: "",
                                                                        saleId: pnrBeforeNIServiceResponse.saleId,
                                                                        transactionId:
                                                                            pnrBeforeNIServiceResponse.transactionId,
                                                                        paymentStatus: false,
                                                                    },
                                                                    paymentInfo: data,
                                                                })
                                                            );
                                                            window.open(
                                                                window.location.origin +
                                                                `/${window.location.pathname.substring(
                                                                    1,
                                                                    3
                                                                )}/booking/booking-confirmation`,
                                                                "_self"
                                                            );
                                                            console.log("error in payment service NI");
                                                        });
                                                    // }
                                                }
                                            })
                                            .catch((err) => {
                                                console.log("pnrBeforeNIService Error");
                                                reject({ status: 500, message: err });
                                            });
                                    })
                                    .catch((detailsError) => {
                                        console.log("detailsError", detailsError);
                                    });
                            })
                            .catch((memberError) => {
                                console.error("memberError", memberError);
                            });
                    });
                } else {
                    return new Promise(async (resolve, reject) => {
                        return await pnrBeforeNIService(requestPayload)
                            .then(async (pnrBeforeNIServiceResponse) => {
                                console.log(
                                    "pnrBeforeNIServiceResponse",
                                    pnrBeforeNIServiceResponse
                                );
                                dispatch(
                                    updateBookingDetailsbyKey({
                                        pnrInfo: {
                                            saleCode: pnrBeforeNIServiceResponse.saleCode,
                                            paymentStatus: pnrBeforeNIServiceResponse.paymentStatus,
                                            saleId: pnrBeforeNIServiceResponse.saleId,
                                            transactionId: pnrBeforeNIServiceResponse.transactionId,
                                        },
                                    })
                                );

                                if (pnrBeforeNIServiceResponse) {
                                    let paymentPayload = {
                                        cartBase: finalCartBase,
                                        mainGuestDetails: bookingDetailsV2.mainGuestDetails,
                                        outletReference: bookingDetailsV2.outletReference,
                                        paymentMethod: bookingDetailsV2.paymentMethod,
                                        sessionId: bookingDetailsV2.sessionId,
                                        saleCode: pnrBeforeNIServiceResponse.saleCode,
                                        ShopCartId: bookingDetailsV2.cartBase.ShopCartId,
                                    };
                                    if (
                                        bookingDetailsV2.paymentMethod == "Apple_Pay" ||
                                        bookingDetailsV2.paymentMethod == "Samsung_Pay"
                                    ) {
                                        // Apple pay
                                        await cardPaymentService(
                                            paymentPayload,
                                            pnrBeforeNIServiceResponse
                                        )
                                            .then(async (paymentServiceResponse) => {
                                                console.log(
                                                    "paymentServiceResponse>>>>>>>>>>>>>",
                                                    paymentServiceResponse
                                                );

                                                //reset pnrInfo in redux before payment
                                                dispatch(
                                                    updateBookingDetailsbyKey({
                                                        pnrInfo: {
                                                            saleCode: "",
                                                            paymentStatus: false,
                                                            saleId: "",
                                                            transactionId: "",
                                                        },
                                                    })
                                                );

                                                dispatch(
                                                    updateBookingDetailsbyKey({
                                                        paymentInfo: paymentServiceResponse,
                                                    })
                                                );
                                                if (bookingDetailsV2.paymentMethod == "Apple_Pay") {
                                                    console.log(
                                                        "APPLE_PAY_URL",
                                                        paymentServiceResponse.applePaypaymentURL
                                                    );
                                                    console.log(
                                                        "wallet_token",
                                                        paymentServiceResponse.wallet_token
                                                    );
                                                    const applePayObject = {
                                                        APPLE_PAY_URL:
                                                            paymentServiceResponse.applePaypaymentURL,
                                                        wallet_token: paymentServiceResponse.wallet_token,
                                                        payment_amount:
                                                            paymentServiceResponse.payment_amount,
                                                    };
                                                    sessionStorage.setItem(
                                                        "ApplePay_payment",
                                                        JSON.stringify(applePayObject)
                                                    );

                                                    resolve();
                                                    // return true;
                                                }
                                            })
                                            .catch((data) => {
                                                dispatch(
                                                    updateBookingDetailsbyKey({
                                                        pnrInfo: {
                                                            ...bookingDetailsV2.pnrInfo,
                                                            paymentStatus: false,
                                                        },
                                                        paymentInfo: data,
                                                    })
                                                );
                                                // window.open(
                                                //   window.location.origin +
                                                //     `/${window.location.pathname.substring(
                                                //       1,
                                                //       3
                                                //     )}/booking/booking-confirmation`,
                                                //   "_self"
                                                // );
                                                console.log("error in payment service NI");
                                            });
                                    } else {
                                        await cardPaymentService(paymentPayload)
                                            .then((paymentServiceResponse) => {
                                                dispatch(
                                                    updateBookingDetailsbyKey({
                                                        paymentInfo: paymentServiceResponse,
                                                    })
                                                );
                                                window.open(
                                                    window.location.origin +
                                                    `/${window.location.pathname.substring(
                                                        1,
                                                        3
                                                    )}/booking/booking-confirmation`,
                                                    "_self"
                                                );
                                                return true;
                                            })
                                            .catch((data) => {
                                                dispatch(
                                                    updateBookingDetailsbyKey({
                                                        pnrInfo: {
                                                            saleCode: "",
                                                            saleId: pnrBeforeNIServiceResponse.saleId,
                                                            transactionId:
                                                                pnrBeforeNIServiceResponse.transactionId,
                                                            paymentStatus: false,
                                                        },
                                                        paymentInfo: data,
                                                    })
                                                );
                                                window.open(
                                                    window.location.origin +
                                                    `/${window.location.pathname.substring(
                                                        1,
                                                        3
                                                    )}/booking/booking-confirmation`,
                                                    "_self"
                                                );
                                                console.log("error in payment service NI");
                                            });
                                    }
                                }
                            })
                            .catch((err) => {
                                console.log("pnrBeforeNIService Error");
                                reject({ status: 500, message: err });
                            });
                    });
                }
            }
        } else {
            if (accountServiceResponse.data.errorMessage) {
                dispatch(actionIsLoading(false));
            }
        }
    };
}

export function confirmationAfterPayment() {
    return async (dispatch, getState) => {
        const { bookingDetailsV2, bookingJourney } = getState();

        let requestPayload = {
            pnrInfo: bookingDetailsV2.pnrInfo,
            cartBase: bookingDetailsV2.cartBase,
            paymentInfo: bookingDetailsV2.paymentInfo,
            mainGuestDetails: bookingDetailsV2.mainGuestDetails,
            outletReference: bookingDetailsV2.outletReference,
            ShopCartId: bookingDetailsV2.cartBase.ShopCartId,
        };

        console.log("confirmationAfterPayment - templateid", bookingDetailsV2.templateid);

        if (bookingDetailsV2.cartBase.TotalAmount > 0) {
            await paymentStatusService(requestPayload)
                .then((paymentStatusServiceResponse) => {
                    let ainDubai = checkIfAinDubaiExistsInCart(
                        requestPayload.cartBase.Items
                    );
                    requestPayload.ainDubai = ainDubai;
                    return new Promise(async (resolve, reject) => {
                        return await pnrAfterNIService(
                            paymentStatusServiceResponse.cardPayload,
                            requestPayload,
                            paymentStatusServiceResponse.shopCartId,
                            bookingJourney.pageData,
                            bookingDetailsV2.passCategory,
                            bookingDetailsV2.templateid,
                        )
                            .then((pnrAfterNIServiceResponse) => {
                                dispatch(actionResetBookingJourneyReducer());
                                purchaseCompleteGTMEvent(
                                    pnrAfterNIServiceResponse,
                                    bookingJourney
                                );
                                if (
                                    pnrAfterNIServiceResponse.saleCode &&
                                    pnrAfterNIServiceResponse.saleId
                                ) {
                                    dispatch(
                                        updateBookingDetailsbyKey({
                                            pnrInfo: {
                                                ...bookingDetailsV2.pnrInfo,
                                                ...pnrAfterNIServiceResponse,
                                                paymentStatus: true,
                                            },
                                        })
                                    );
                                }
                                resolve({ status: 200, message: pnrAfterNIServiceResponse });
                            })
                            .catch((err) => {
                                console.log(err);
                                dispatch(actionResetBookingJourneyReducer());
                                reject({ status: 500, message: err });
                            });
                    });
                })
                .catch((err) => {
                    dispatch(
                        updateBookingDetailsbyKey({
                            pnrInfo: { ...bookingDetailsV2.pnrInfo, paymentStatus: false },
                        })
                    );

                    dispatch(actionResetBookingJourneyReducer());

                    console.log(err, "payment Status error");
                });
        }
        else
        {
            let ainDubai = checkIfAinDubaiExistsInCart(
                requestPayload.cartBase.Items
            );
            requestPayload.ainDubai = ainDubai;
            return new Promise(async (resolve, reject) => {
                return await pnrAfterNIService(
                    "",
                    requestPayload,
                    bookingDetailsV2.cartBase.ShopCartId,
                    bookingJourney.pageData,
                    bookingDetailsV2.passCategory,
                    bookingDetailsV2.templateid,
                )
                    .then((pnrAfterNIServiceResponse) => {
                        dispatch(actionResetBookingJourneyReducer());
                        purchaseCompleteGTMEvent(
                            pnrAfterNIServiceResponse,
                            bookingJourney
                        );
                        if (
                            pnrAfterNIServiceResponse.saleCode &&
                            pnrAfterNIServiceResponse.saleId
                        ) {
                            dispatch(
                                updateBookingDetailsbyKey({
                                    pnrInfo: {
                                        ...bookingDetailsV2.pnrInfo,
                                        ...pnrAfterNIServiceResponse,
                                        paymentStatus: true,
                                    },
                                })
                            );
                        }
                        resolve({ status: 200, message: pnrAfterNIServiceResponse });
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(actionResetBookingJourneyReducer());
                        reject({ status: 500, message: err });
                    });
            });
        }            
    };
}

const checkIfAinDubaiExistsInCart = (items) => {
    return items.some((each) => each.ProductName.includes("Ain Dubai"));
};

export function downloadPDF() {
    return async (dispatch, getState) => {
        const { bookingDetailsV2 } = getState();

        let requestPayload = {
            downloadUrl: bookingDetailsV2.pnrInfo.pahDownloadUrl,
        };

        return new Promise(async (resolve, reject) => {
            await pdfService(requestPayload)
                .then((pdfServiceResponse) => {
                    resolve(pdfServiceResponse);
                })
                .catch((err) => {
                    reject(err);
                    console.log(err, "pdf Download error");
                });
        });
    };
}

const getProduct = (data, ProductId) => {
    return data.find((findValue) => findValue.ProductId == ProductId);
};

const purchaseCompleteGTMEvent = (data, pageData) => {
    let arrival_date = "";
    let tickets = [];
    let addOns = [];
    if (data && data.transactionDetails && data.transactionDetails.length > 0) {
        data.transactionDetails.forEach((item) => {
            if (item.VisitDate) {
                arrival_date = item.VisitDate;
            }
            if (
                item.ProductName.toLowerCase() ==
                "DAY PASS (for non-residents) (Child)".toLowerCase() ||
                item.ProductName.toLowerCase() ==
                "DAY PASS (for non-residents) (Adult)".toLowerCase() ||
                item.ProductName.toLowerCase() ==
                "ALL-INCLUSIVE F&B (for non-residents) (Adult)".toLowerCase() ||
                item.ProductName.toLowerCase() ==
                "ALL-INCLUSIVE F&B (for non-residents) (Child)".toLowerCase() ||
                item.ProductName.toLowerCase() ==
                "ALL-INCLUSIVE F&B (for UAE residents) (Adult)".toLowerCase() ||
                item.ProductName.toLowerCase() ==
                "ALL-INCLUSIVE F&B (for UAE residents) (Child)".toLowerCase() ||
                item.ProductName.toLowerCase() == "Annual Pass".toLowerCase() ||
                item.ProductName.toLowerCase() ==
                "DAY PASS (for UAE residents) (Adult)".toLowerCase() ||
                item.ProductName.toLowerCase() ==
                "DAY PASS (for UAE residents) (Child)".toLowerCase()
            ) {
                tickets.push({
                    ticket_name: item.ProductName,
                    ticket_qty: item.Quantity,
                    ticket_amount: item.TicketAmount,
                });
            } else if (
                item.ProductName.toLowerCase() ==
                "All You Can Eat (Child)".toLowerCase() ||
                item.ProductName.toLowerCase() ==
                "All You Can Eat (Adult)".toLowerCase() ||
                item.ProductName.toLowerCase() == "Fast Pass".toLowerCase()
            ) {
                addOns.push({
                    addon_name: item.ProductName,
                    addon_qty: item.Quantity,
                    addon_rate: item.TicketAmount,
                });
            } else {
            }
        });

        let totalAmount =
            tickets.length > 0
                ? tickets.reduce(
                    (accumulator, obj) => accumulator + obj.ticket_amount,
                    0
                )
                : 0 + addOns.length > 0
                    ? addOns.reduce((accumulator, obj) => accumulator + obj.addon_rate, 0)
                    : 0;

        let total_tickets_amounts =
            tickets.length > 0
                ? tickets.reduce(
                    (accumulator, obj) => accumulator + obj.ticket_amount,
                    0
                )
                : 0;

        let total_addon_rates =
            addOns.length > 0
                ? addOns.reduce((accumulator, obj) => accumulator + obj.addon_rate, 0)
                : 0;

        window.dataLayer.push({
            event: "purchase_complete",
            page_type: "Home Page",
            page_language: "en",
            arrival_date: arrival_date,
            cart_details: tickets,
            total_tickets:
                tickets.length > 0 &&
                tickets.reduce((accumulator, obj) => accumulator + obj.ticket_qty, 0),
            total_tickets_amount: total_tickets_amounts,

            addon_cart_details: addOns,
            total_addon_qty:
                addOns.length > 0
                    ? addOns.reduce((accumulator, obj) => accumulator + obj.addon_qty, 0)
                    : 0,
            total_addon_rate: total_addon_rates,
            total_amount: total_tickets_amounts + total_addon_rates,
        });

        // dataLayer.push({ ecommerce: null });
        let purchaseCompleteGA4 = [];

        data &&
            data.transactionDetails &&
            data.transactionDetails.length > 0 &&
            data.transactionDetails.forEach((item) => {
                let item_categoryy = "";
                if (item.ProductName.toLowerCase().includes("resident")) {
                    item_categoryy = "Day Pass";
                } else {
                    item_categoryy = "Extras";
                }
                const newItem = {
                    item_name: item.ProductName,
                    affiliation: "Wild Wadi",
                    index: 0,
                    item_category: item_categoryy,
                    price: item.TicketAmount,
                    quantity: item.Quantity,
                };

                if (
                    item.ProductName.toLowerCase().includes("adult") ||
                    item.ProductName.toLowerCase().includes("(ad)")
                ) {
                    newItem.item_category2 = "adult";
                } else if (
                    item.ProductName.toLowerCase().includes("child") ||
                    item.ProductName.toLowerCase().includes("(ch)")
                ) {
                    newItem.item_category2 = "child";
                }
                purchaseCompleteGA4.push(newItem);
            });
        // GA4 Event
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: "purchase",
            transaction_date: arrival_date,
            booking_date: arrival_date,
            ecommerce: {
                transaction_id: data.transactionId,
                value:
                    data &&
                    data.transactionDetails.reduce(
                        (acc, curr) => acc + curr.TicketAmount,
                        0
                    ),
                currency: "AED",
                coupon: "",
                items: purchaseCompleteGA4,
            },
        });
    }
};

const getCurrentDate = () => {
    // Get the current date
    const currentDate = new Date();

    // Format the date as "YYYY-MM-DD"
    const year = currentDate.getFullYear().toString();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
    let day = currentDate.getDate().toString().padStart(2, "0");

    // Combine the parts to form the final date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};

const addToCartGA4EventCall = (Id, Qty, bookingJourney, allTickets) => {
    if (Qty !== -1) {
        console.log("add_to_cart_ticket", bookingJourney);

        window.dataLayer.push({
            event: "add_to_cart_ticket",
            page_type: bookingJourney.pageData.name,
            page_language: bookingJourney.pageData.itemLanguage,
            arrival_date: bookingJourney.bookingDate,
            ticket_name: getProduct(allTickets, Id).ProductName,
            ticket_qty: Qty,
            ticket_amount: `${getProduct(allTickets, Id).Price}`,
        });
        // GA4 Event
        window.dataLayer.push({ ecommerce: null });
        console.log("add_to_cart", bookingJourney);
        window.dataLayer.push({
            event: "add_to_cart",
            transaction_date: bookingJourney.selectPassCategory.passCategory
                ? getCurrentDate()
                : bookingJourney.bookingDate,
            booking_date: bookingJourney.selectPassCategory.passCategory
                ? ""
                : bookingJourney.bookingDate,
            ecommerce: {
                currency: "AED",
                value: getProduct(allTickets, Id).Price,
                items: [
                    {
                        item_id: Id,
                        item_name: getProduct(allTickets, Id).ProductName,
                        affiliation: "Wild Wadi",
                        index: 0,
                        item_category: bookingJourney.selectPassCategory.passCategory
                            ? "Annual Pass"
                            : "Day Pass",
                        item_category2: "",
                        price: getProduct(allTickets, Id).Price,
                        quantity: Qty == null ? 1 : Qty,
                    },
                ],
            },
        });
    } else {
        return;
    }
};
