export const TICKETS_DATA = "TICKETS_DATA";
export const ADD_TO_CART_ITEM = "ADD_TO_CART_ITEM";
export const APPLIED_COUPON = "APPLIED_COUPON";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const BOOKING_JOURNEY_STEPS_HANDLER = "BOOKING_JOURNEY_STEPS_HANDLER";
export const GET_DATE = "GET_DATE";
export const GET_PRODUCTID = "GET_PRODUCTID";
export const ADD_TO_CART_DETAILS = "ADD_TO_CART_DETAILS";
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const IS_MATCH_PID = "IS_MATCH_PID";
export const SAVE_ADD_ONS_DATA = "SAVE_ADD_ONS_DATA";
export const SAVE_SITECORE_ADD_ONS_DATA = "SAVE_SITECORE_ADD_ONS_DATA";
export const SAVE_PERFORMANCEID = "SAVE_PERFORMANCEID";
export const SAVE_PAGE_DATA = "SAVE_PAGE_DATA";
export const BUTTON_LOADING = "BUTTON_LOADING";
export const ADD_TO_CART_RESPONSE = "ADD_TO_CART_RESPONSE";
export const EDIT_TO_CART_RESPONSE = "EDIT_TO_CART_RESPONSE";
export const REMOVE_FROM_CART_RESPONSE = "REMOVE_FROM_CART_RESPONSE";
export const IS_LOADING = "IS_LOADING";

export const PAGE_TYPE_CONTEXT = "PAGE_TYPE_CONTEXT";
export const PAGE_CTA_CONTEXT = "PAGE_CTA_CONTEXT";
export const PATH_CONTEXT = "PATH_CONTEXT";
export const PAGE_LANGUAGE = "PAGE_LANGUAGE";

export const SAVE_SITECORE = "SAVE_SITECORE";

export const ITEMS_TO_BE_ADD = "ITEMS_TO_BE_ADD";
export const ITEMS_TO_BE_UPDATE = "ITEMS_TO_BE_UPDATE";

export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const ACCORDION_STATE = "ACCORDION_STATE";
export const SAVE_CART_AMOUNT = "SAVE_CART_AMOUNT";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const TEMPORARY_SAVE_ADD_ONS = "TEMPORARY_SAVE_ADD_ONS";
export const SAVE_MAXIMUM_ADD_ON_QTY = "SAVE_MAXIMUM_ADD_ON_QTY";
export const SAVE_PRODUCT_ID_FOR_EVENTS = "SAVE_PRODUCT_ID_FOR_EVENTS";
export const SAVE_ACTION_PERFORMED = "SAVE_ACTION_PERFORMED";
export const SAVE_LOCKERS_DATA = "SAVE_LOCKERS_DATA";

export const RESET_BOOKING_JOURNEY_REDUCER = "RESET_BOOKING_JOURNEY_REDUCER";
export const EMPTY_UNUSED_VALUES = "EMPTY_UNUSED_VALUES";

export const SAVE_SELECTED_LOCKER_DROPDOWN_VALUE =
  "SAVE_SELECTED_LOCKER_DROPDOWN_VALUE";

export const SAVE_MAX_TICKET_ADDED = "SAVE_MAX_TICKET_ADDED";

export const SAVE_MAX_ADDONS_ADDED = "SAVE_MAX_ADDONS_ADDED";
export const IS_BACK_BTN_PRESS = "IS_BACK_BTN_PRESS";

export const SELECT_TOUR_TYPE = "SELECT_TOUR_TYPE";

// select category
export const SELECT_PASS_CATEGORY = "SELECT_PASS_CATEGORY";
export const SELECT_ACCOUNT_CATEGORY = "SELECT_ACCOUNT_CATEGORY"
export const SELECT_TICKET_TEMPLATE = "SELECT_TICKET_TEMPLATE"
// member details
export const ADD_MEMBER_DETAILS = "ADD_MEMBER_DETAILS";

export const IS_PROCEED_TO_PAY_CLICKED = "IS_PROCEED_TO_PAY_CLICKED";

export const SAVE_TOTAL_TICKET_QUANTITY = "SAVE_TOTAL_TICKET_QUANTITY";

export const actionAddTicketsData = (payload) => {
  return { type: TICKETS_DATA, payload: payload };
};

export const actionAddToCartItem = (payload) => {
  return { type: ADD_TO_CART_ITEM, payload: payload };
};

export const actionHandleAppliedCoupon = (payload) => {
  return { type: APPLIED_COUPON, payload: payload };
};

export const actionBookingJourneyStepsHandler = (payload) => {
  return { type: BOOKING_JOURNEY_STEPS_HANDLER, payload: payload };
};
export const actionRemoveCartItem = (payload) => {
  return { type: REMOVE_CART_ITEM, payload: payload };
};

export const actionUpdateCartItem = (payload) => {
  return { type: UPDATE_CART_ITEM, payload: payload };
};

export const actionGetDate = (payload) => {
  return { type: GET_DATE, payload: payload };
};

export const actionGetProductId = (payload) => {
  return { type: GET_PRODUCTID, payload: payload };
};

export const actionAddToCartDetails = (payload) => {
  return { type: ADD_TO_CART_DETAILS, payload: payload };
};

export const actionSaveUserDetail = (payload) => {
  return { type: SAVE_USER_DETAILS, payload: payload };
};

export const actionIsMatchPID = (payload) => {
  return { type: IS_MATCH_PID, payload: payload };
};

export const actionSaveAddOnsData = (payload) => {
  return { type: SAVE_ADD_ONS_DATA, payload: payload };
};

export const actionSaveSitecoreAddOnsData = (payload) => {
  return { type: SAVE_SITECORE_ADD_ONS_DATA, payload: payload };
};

export const actionSavePerformanceId = (payload) => {
  return { type: SAVE_PERFORMANCEID, payload: payload };
};

export const actionSavePageData = (payload) => {
  return { type: SAVE_PAGE_DATA, payload: payload };
};
export const actionButtonLoader = (payload) => {
  return { type: BUTTON_LOADING, payload: payload };
};

export const actionSaveAddToCartResponse = (payload) => {
  return { type: ADD_TO_CART_RESPONSE, payload: payload };
};
export const actionSaveEditToCartResponse = (payload) => {
  return { type: EDIT_TO_CART_RESPONSE, payload: payload };
};
export const actionSaveRemoveFromCartResponse = (payload) => {
  return { type: REMOVE_FROM_CART_RESPONSE, payload: payload };
};

export const actionIsLoading = (payload) => {
  return { type: IS_LOADING, payload: payload };
};

export const actionSaveSitecore = (payload) => {
  return { type: SAVE_SITECORE, payload: payload };
};

export const actionSaveItemsToBeAdd = (payload) => {
  return { type: ITEMS_TO_BE_ADD, payload: payload };
};

export const actionSaveItemsToBeUpdate = (payload) => {
  return { type: ITEMS_TO_BE_UPDATE, payload: payload };
};

export const actionRemoveFromCartRequest = (payload) => {
  return { type: REMOVE_FROM_CART_REQUEST, payload: payload };
};

export const actionAccordionState = (payload) => {
  return { type: ACCORDION_STATE, payload: payload };
};

export const actionSaveCartAmount = (payload) => {
  return { type: SAVE_CART_AMOUNT, payload: payload };
};

export const actionAddProducts = (payload) => {
  return { type: ADD_PRODUCTS, payload: payload };
};

export const actionUpdateProducts = (payload) => {
  return { type: UPDATE_PRODUCTS, payload: payload };
};

export const actionTemporarySaveAddOns = (payload) => {
  return { type: TEMPORARY_SAVE_ADD_ONS, payload: payload };
};

export const actionSaveMaximumAddonQty = (payload) => {
  return { type: SAVE_MAXIMUM_ADD_ON_QTY, payload: payload };
};
export const actionSavePIdForEvent = (payload) => {
  return { type: SAVE_PRODUCT_ID_FOR_EVENTS, payload: payload };
};

export const actionSaveActionPerformed = (payload) => {
  return { type: SAVE_ACTION_PERFORMED, payload: payload };
};

export const actionSaveLockerData = (payload) => {
  return { type: SAVE_LOCKERS_DATA, payload: payload };
};

export const actionSaveSelectedLockerValue = (payload) => {
  return { type: SAVE_SELECTED_LOCKER_DROPDOWN_VALUE, payload: payload };
};

export const actionSaveMaxTicketAdded = (payload) => {
  return { type: SAVE_MAX_TICKET_ADDED, payload: payload };
};

export const actionSaveMaxAddonsAdded = (payload) => {
  return { type: SAVE_MAX_ADDONS_ADDED, payload: payload };
};

export const actionResetBookingJourneyReducer = () => {
  return { type: RESET_BOOKING_JOURNEY_REDUCER };
};

export const actionEmptyUnusedValues = () => {
  return { type: EMPTY_UNUSED_VALUES };
};

export const selectPassCategory = (payload) => {
  return { type: SELECT_PASS_CATEGORY, payload: payload };
};

export const selectTourType = (payload) => {
    return { type: SELECT_TOUR_TYPE, payload: payload };
}

export const addMemberDetails = (payload) => {
  return { type: ADD_MEMBER_DETAILS, payload: payload };
};

export const actionIsProccedToPayClicked = (payload) => {
  return { type: IS_PROCEED_TO_PAY_CLICKED, payload: payload };
};

export const selectAccountCategory = (payload) => {
    return { type: SELECT_ACCOUNT_CATEGORY, payload: payload };
};
export const selectTicketTemplate = (payload) => {
    return { type: SELECT_TICKET_TEMPLATE, payload: payload };
};