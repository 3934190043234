"use strict";

var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];

          for (var p in s) {
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
          }
        }

        return t;
      };

    return __assign.apply(this, arguments);
  };

Object.defineProperty(exports, "__esModule", {
  value: true,
});

var ResponseError =
  /** @class */
  (function (_super) {
    function ResponseError(message, response) {
      var _this = _super.call(this, message) || this;

      Object.setPrototypeOf(_this, ResponseError.prototype);
      _this.response = response;
      return _this;
    }

    return ResponseError;
  })(Error);

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  var error = new ResponseError(response.statusText, response);
  throw error;
} // note: encodeURIComponent is available via browser (window) or natively in node.js
// if you use another js engine for server-side rendering you may not have native encodeURIComponent
// and would then need to install a package for that functionality

function getQueryString(params) {
  return Object.keys(params)
    .map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    })
    .join("&");
}

function fetchData(url, fetcher, params) {
  if (params === void 0) {
    params = {};
  }

  var qs = getQueryString(params);
  var fetchUrl = url.indexOf("?") !== -1 ? url + "&" + qs : url + "?" + qs;
  return fetcher(fetchUrl)
    .then(checkStatus)
    .then(function (response) {
      // axios auto-parses JSON responses, don't need to JSON.parse
      return response.data;
    });
}

var resolveLayoutServiceUrl = function resolveLayoutServiceUrl(options, verb) {
  if (options === void 0) {
    options = {};
  }

  var _a = options.host,
    host = _a === void 0 ? "" : _a,
    _b = options.configurationName,
    configurationName = _b === void 0 ? "jss" : _b,
    serviceUrl = options.serviceUrl;

  if (serviceUrl) {
    return serviceUrl;
  }

  return host + "/sitecore/api/layout/" + verb + "/" + configurationName;
};
/**
 * Makes a request to Sitecore Layout Service for the specified route item path.
 */

function fetchRouteData(itemPath, options) {
    var querystringParams = options.querystringParams,
    layoutServiceConfig = options.layoutServiceConfig;
    var fetchUrl = resolveLayoutServiceUrl(layoutServiceConfig, "render");
    //console.log('website URL', fetchUrl);
    console.log('fetchRouteData', itemPath);
    const route = window.location.pathname;
    let item;
    let lastTwoPaths;
    const paths = route.split("/").filter((route) => route.length > 0);
    //console.log("paths", paths);
    if (paths.length > 2)
        lastTwoPaths = paths.slice(-2);
    else
        lastTwoPaths = paths.slice(-1);
    const newUrl = lastTwoPaths.join("/");
    // console.log("newUrl",newUrl);
  if (route) {
    switch (newUrl) {
      //case "home":
      //  item = "F90D90C3-A74A-481D-94CB-F1A64B94088D";
      //  break;
      case "information":
        item = "3959870d-7930-45b9-9420-7afb625e0e3c";
        break;
      case "explore":
        item = "76A56E38-882A-4B0A-BBE3-019E58C079E4";
        break;
      case "safety":
        item = "F0E961D8-5F3A-41BE-921E-FFF419AADC0B";
        break;
      case "booking":
        item = "7651ec77-fde8-4370-9087-88b53486fec6";
        break;
      case "half-term-cool-down":
        item = "C58B6EFD-F39A-490D-945B-8748AB1E3918";
        break;
      // case "school-trips":
      //   item = "D02A0433-CD18-4094-830F-AD67B6BC9828";
      //   break;
      case "offers/midterm":
        item = "A24F93C7-14FA-4AA5-8C66-1CAD5D0DC251";
        break;
      case "offers/flash-sale":
        item = "908DAE8B-6E44-4B96-9695-965444FB9888";
        break;
      case "offers/abu-dhabi-municipality-roadshow":
        item = "793E613E-20AC-4BA2-A7AC-AAF9AA157C0F";
        break;
      case "offers/eid-al-adha":
        item = "4424F1D2-C2E0-427B-831D-D67994826B4A";
        break;
      case "offers/new-years-eve":
        item = "6C329907-7C0F-4823-BDAF-1EF5543FE2C4";
        break;
      case "offers/ladies-night":
        item = "232FF263-D893-4BDF-A15E-16C7914961F7";
        break;
      case "booking/new-years-eve":
        item = "2D1A0874-A2E2-488C-A4C1-7B86D5E30C96";
        break;
      case "offers/movember":
        item = "82984A93-72F7-4805-A730-F074E208163D";
        break;
       case "booking/ladies-night":
        item = "43830142-C3B0-402C-B434-F98310D4E864";
        break;
      case "booking/movember":
        item = "0060638F-3437-43AF-A356-D7C107C671E9";
        break;
      case "booking/festive":
        item = "521BE712-CD93-4E10-8623-95B5059DC468";
        break;
      case "booking/kuwait-national-day":
        item = "DA31D539-D03B-49B1-818E-AEAFEFD4BF49";
        break;
      case "booking/winter-pass":
        item = "9E26DE5B-F1CF-46FB-8D91-B15A5916FF71";
        break;
      case "offers/kuwait-national-day":
        item = "FE96FCC7-AA92-4906-9EF8-8F408E7B8B6B";
        break;
      case "offers/saudi-national-day":
        item = "5A0C0220-7D57-4A54-B6F1-750CC16AD518";
        break;
      case "offers/festive":
        item = "F9A176D1-C05A-49D9-8717-B2C4CAA170A2";
        break;
      case "offers/summer":
        item = "E92E3601-8A2A-4375-BD99-E50C0DF969A8";
        break;
      case "offers/halloween":
        item = "0FA48013-4A98-4E01-8075-440E945F6AD9";
        break;
      case "booking/day-pass-old":
        item = "CF5A4B40-8AEB-4E1C-8FEB-325DB0C4A169";
        break;
      case "booking/day-pass":
        item = "CF5A4B40-8AEB-4E1C-8FEB-325DB0C4A169";
        break;     
      case "booking/flash-sale":
        item = "0A844BB8-9086-40FF-B12B-C9AB6B90327E";
        break;
      case "booking/abu-dhabi-municipality-roadshow":
        item = "0180F65F-97C1-4F77-948A-5B7B9CC44D18";
        break;
      case "booking/emirates-platinum":
        item = "8E87EBF1-6835-4C95-A14B-826213E44674";
        break;
      case "booking/flow-rider-championship":
        item = "aeff6d8d-5745-44dd-8f6b-9a44edf43b25";
        break;
      case "booking/flow-rider-championship-corporate":
        item = "a0c905ba-cb2d-4fd6-85f5-d3411a79dc1b";
        break;
      case "booking/private-cabana-packages":
        item = "C8E52EA0-4F22-4CFC-B504-EC4EA2EA337E";
        break;        
      case "booking/annual-pass":
        item = "F4BE0559-7F7B-4D3E-AA82-0AC84039665A"; 
        break;
      case "booking/road-show":
        item = "E13492B5-6F5D-4952-9F8B-F1D1EBA10B40"; 
        break;
      case "booking/visa-mondays":
        item = "3D77B2DA-1906-40CE-AF87-CB77969F5014";            
        break;
      case "booking/rugby-participants":
        item = "061EB55F-35A3-43E1-A3E1-6260C6519F8D"; 
        break;
      case "booking/rugby-attendees":
        item = "E87A25A0-8261-4FA5-BB45-2E461B7B6F78"; 
        break;
      case "booking/oman-national-day":
        item = "29D77607-9CD9-4F7D-8530-11D61614E157";
        break;
      case "booking/qatar-national-day":
        item = "cef9a543-7d08-41da-8270-329360c679ac";
        break;
      case "booking/saudi-national-day":
        item = "0C4D77D0-A4D0-4325-B7B9-CB5F6CA33BA6";
        break;
      case "booking/resident-combo-deal-day-pass-meal-combo":
        item = "eb333beb-b1c5-49ea-bfd5-ad4775dae5cf";
        break;
      case "booking/dafza-roadshow":
         item = "eaf2c08c-6502-4f89-9b35-93eb4f85d7c7";
         break;
      case "booking/world-children-day":
        item = "EF068E9F-3875-4838-8DF1-1772CC987841";
        break;
      case "offers/resident-combo-deal-day-pass-meal-combo":
        item = "8ec94c30-c288-4cfe-865c-e24d7c521166";
        break;
      case "booking/bahrain-national-day":
        item = "e7678539-ea91-4818-ac20-bdea324b07f3";
        break;
      case "booking/dh-offer-day-pass":
        item = "42F69F23-EA57-4B67-91E2-F77E1A7EF1E6"; 
        break;
      case "let-the-good-times-flow":
        item = "6A3C1BC4-272A-4EC3-A2E7-7A6430E6BA31";
        break;
      case "booking/gift-certificates":
        item = "66953ae8-d08d-4c57-970b-b47961340aab";
        break;
      case "booking/iranian-new-year":
        item = "b0b2a9d2-8985-4399-8770-892daa7120cf";
        break;
      case "offers/gift-certificates":
        item = "4b1b1d6d-0430-4554-a4db-f5be86a001dc";
        break;
      case "offers/world-children-day":
        item = "C39893EC-C5D5-4E59-A924-24C10DA3B7F5";
        break;
     case "booking/anniversary":
        item = "a145b771-730d-4168-99d5-616f81187d95";
        break;
      case "offers/festive-summer-land":
        item = "94ef8638-0f40-4f6a-9f45-b49df9092ed5";
        break;
      case "offers/bahrain-national-day":
        item = "dca52f0b-f352-4415-b841-51d901b42048";
        break;
      case "offers/qatar-national-day":
        item = "ef08e938-dd6f-459d-9abf-7384ffb9d65e";
        break;
      case "offers/dafza-roadshow":
        item = "d08ec3ba-9a63-4ec2-90ac-21617e820359";
        break;
      case "offers/iranian-new-year":
        item = "9781fba2-ad3a-4242-92b2-0c5c6687287e";
        break;
      case "offers/spring-break":
        item = "2ff8a1c9-a772-4c25-ab17-10fc3adcfc64";
        break;
      case "offers/easter":
        item = "d87414ff-b8fc-43ec-b5f3-f2363f459d22";
        break;
      case "offers/flow-rider-championship":
        item = "ec0a2cf6-bc45-4f9b-8ecf-c08b75ab8881";
        break;
      case "offers/flow-rider-championship-corporate":
        item = "fd042b71-7f5c-45d9-9036-ea0dfe75d4bc";
        break;
      case "offers/anniversary":
        item = "adab1af8-9d67-42e2-8074-36877e8cc3c5";
        break;
      case "offers/eid-mubarak":
        item = "475c2c57-9816-4d83-8022-9c2cb2e3c175";
        break;
      case "booking/booking-confirmation":
        item = "0842e8a6-d764-4c2c-bff9-7ed563d44a1e";
        break;
      case "booking/ramadan-offer":
        item = "4E5866E3-E0D9-40C8-AD26-B30F2A7C6A48";
        break;
      case "rates-and-offers":
        item = "296a3e31-1495-4b62-ad9a-e0995e0a74a6";
        break;
      case "plan-your-visit":
        item = "F0D19604-D332-44E7-BF27-AB4D2ED1709B";
        break;
      case "contact/contact-us":
        item = "9B8C61A9-B2A9-4743-ABBD-DCA0D64E51C8";
        break;
      case "contact/conditions_en":
        item = "E0951572-4732-4A80-87ED-AB0ADD999B74";
        break;
      case "contact/terms-and-conditions_ar":
        item = "121FF219-0C0C-4353-9C7F-DE0C2C03CAC3";
        break;
      case "about-us":
        item = "2A18999A-6394-491E-8526-40E27FCF7B1D";
        break;
      case "faq":
        item = "7E1E90FE-042A-49B2-B82B-2A257D499BE8";
        break;
      case "tickets":
        item = "b4ca2491-0c02-4b45-82f7-6a7190836e01";
        break;
      case "offers/uae-national-day":
         item = "e2a1e663-69ea-4c89-a11e-fed9e2266d23";
        break;
      case "offers/visa-offers":
        item = "f7a3b79e-ae21-49c4-802f-fe472fd862b8";
        break;
      case "offers/hsbc-offer":
        item = "be40d932-e885-4c32-8902-c7b581c0484e";
        break;
      case "offers/eid-al-fitr":
        item = "01C52755-1766-42CB-8CCA-5A89B88E44D1";
        break;
      case "booking/visa-offers":
        item = "b1a81cac-09c4-47a8-842e-4430dd14e0f4";
        break;
      case "booking/hsbc-offer":
        item = "4675ed6b-fdec-4918-97a3-2db82a3e5b7e";
        break;        
      case "booking/uae-national-day-annual-pass":
        item = "5f9ba330-2ed3-48b5-af9c-e98d2f066442";
        break; 
      case "booking/uae-national-day-residents-offer":
        item = "f8d52ad2-f62e-4220-be17-bcb33c5700d6";
        break;
      case "information/privacy-policy":
        item = "93f18d0f-0b2d-4721-9b84-15b5d1e026d4";
        break;
      case "information/faq":
        item = "7e1e90fe-042a-49b2-b82b-2a257d499be8";
        break;
      default:
        item = "F90D90C3-A74A-481D-94CB-F1A64B94088D";
        break;
    }
  }


  // console.log("Item",item);
  return fetchData(
    fetchUrl,
    options.fetcher,
    __assign(
      {
        // item: pageNameUAT, UAT
        item: item,
        //item: itemPath,
      },
      querystringParams
    )
  );
}

exports.fetchRouteData = fetchRouteData;
/**
 * Makes a request to Sitecore Layout Service for the specified placeholder in
 * a specific route item. Allows you to retrieve rendered data for individual placeholders instead of entire routes.
 */

function fetchPlaceholderData(placeholderName, itemPath, options) {

  var querystringParams = options.querystringParams,
    layoutServiceConfig = options.layoutServiceConfig;
  var fetchUrl = resolveLayoutServiceUrl(layoutServiceConfig, "placeholder");
  return fetchData(
    fetchUrl,
    options.fetcher,
    __assign(
      {
        placeholderName: placeholderName,
        item: itemPath,
      },
      querystringParams
    )
  );
}

exports.fetchPlaceholderData = fetchPlaceholderData;
